import {handleErrorWithSentry} from "@sentry/sveltekit";
import * as Sentry from '@sentry/sveltekit';

/**
 * @see https://vitejs.dev/guide/env-and-mode.html#env-files
 */
if (import.meta.env.SENTRY_DSN ?? null) {
    Sentry.init({
        dsn: import.meta.env.SENTRY_DSN,
        environment: import.meta.env.SENTRY_ENVIRONMENT ?? import.meta.env.MODE ?? import.meta.env.NODE_ENV ?? 'development',
        release: import.meta.env.SENTRY_RELEASE ?? null,
        tracesSampleRate: 1.0,
    });
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
